import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/navigatordocument/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/navigatordocument/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/navigatordocument/${data.id}`, data);
    else return axios.post(`/api/navigatordocument`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/navigatordocument/${id}`);
  },
  getRtf: function (id) {
    return Utils.downloadFile(`/api/navigatorDocument/rtf?id=${id}`, 'document.rtf');
  },
  updateDocuments: function () {
    return axios.put(`/api/navigatordocument/updateExternalDocuments`);
  },
};
